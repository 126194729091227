import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { toHumanReadableDateDay } from '../../common/dateTimeHelper';
import TitleSection from '../../components/layout/TitleSection';
import { FeedLatestEvents } from '../store/datasetQueries/feedLatestEvents';
import FeedActivityEventDetails from './FeedActivityEventDetails';

export type FeedEventsListProps = {
  events: FeedLatestEvents;
  groupingOption: 'byHour' | 'byDay';
  groupBy?: string;
  className?: string;
};

const FeedEventsList: React.FC<FeedEventsListProps> = props => {
  const { t } = useTranslation('feed');
  const { events, groupingOption } = props;

  // Determine grouping key based on option
  const getGroupingKey = event => {
    const eventDateEnd = new Date(event.eventEndTime);
    const eventDateStart = new Date(event.eventStartTime ?? null);

    return groupingOption === 'byHour'
      ? `${toHumanReadableDateDay(eventDateEnd)} (${eventDateStart.getHours()}:00 – ${eventDateEnd.getHours()}:00)`
      : toHumanReadableDateDay(eventDateEnd);
  };

  // Group filtered events
  const groupedEvents = useMemo(() => {
    return _(events)
      .groupBy(event => getGroupingKey(event))
      .map((events, key) => ({
        groupKey: key,
        events: _.orderBy(
          events,
          ['eventEndTime', 'eventStartTime', 'agentId'],
          ['desc', 'desc', 'asc'],
        ),
      }))
      .value();
  }, [events, groupingOption]);

  // Prepare events for Virtuoso
  const eventsForVirtuoso = useMemo(() => {
    return groupedEvents.reduce((acc, group) => {
      acc.push({
        type: 'header',
        content: group.groupKey,
        events: group.events,
      });
      return acc;
    }, []);
  }, [groupedEvents]);

  // Calculate if there are multiple unique days or hours for the collapsible condition
  const hasMultipleTimeGroups = useMemo(
    () => groupedEvents.length > 1,
    [groupedEvents],
  );

  return (
    <div
      data-component="FeedEventsList"
      className={classNames('flex flex-col h-full', props.className)}
    >
      <Virtuoso
        className={classNames('flex-grow overflow-x-hidden')}
        data={eventsForVirtuoso}
        itemContent={(index, item) => {
          if (item.type === 'header') {
            return (
              <TitleSection
                id={`header-event-${props.groupBy}-${index}`}
                key={`header-event-${props.groupBy}-${index}`}
                title={item.content}
                isSticky
                className="!top-0"
                fullWidth
                inPanelView
                collapsible={hasMultipleTimeGroups}
              >
                {item.events.map((evt, eventIndex) => (
                  <FeedActivityEventDetails
                    key={`feed-event-details-${evt.eventId}`}
                    event={evt}
                    index={eventIndex}
                  />
                ))}
              </TitleSection>
            );
          }
        }}
        components={{
          List: React.forwardRef((props, ref) => (
            <div
              data-component="FeedEventsListComponents"
              ref={ref}
              {...props}
              className=""
            />
          )),
        }}
      />
    </div>
  );
};

export default FeedEventsList;

import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AssignmentComplianceTable from '../../assignmentCompliance/AssignmentComplianceTable';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import AssignmentOccupancyTable from '../../occupancy/AssignmentOccupancyTable';
import SimulationComplianceLayoutView from '../SimulationComplianceLayoutView';
import AssignmentComplianceLocationLayer from '../layers/AssignmentComplianceLocationLayer';
import AssignmentOccupancyLocationLayer from '../layers/AssignmentOccupancyLocationLayer';
import SizeComplianceLocationLayer from '../layers/SizeComplianceLocationLayer';
import WeightComplianceLocationLayer from '../layers/WeightComplianceLocationLayer';
import SizeComplianceLocationsTable from '../panels/compliance/size/SizeComplianceLocationsTable';
import WeightComplianceBaysTable from '../panels/compliance/weight/WeightComplianceBaysTable';
import WeightComplianceLocationsTable from '../panels/compliance/weight/WeightComplianceLocationsTable';
import { assignmentComplianceDataTableState } from '../store/assignmentCompliance.state';
import { simulationShowComplianceTable } from '../store/simulation.state';
import { simulationComplianceTabKey } from '../store/simulation.wizard.state';
import AssignmentOccupancyToolbar from '../toolbars/AssignmentOccupancyToolbar';
import SizeComplianceToolbar from '../toolbars/SizeComplianceToolbar';
import WeightComplianceToolbar from '../toolbars/WeightComplianceToolbar';

const SimulationComplianceView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [showTable, setShowTable] = useRecoilState(
    simulationShowComplianceTable,
  );
  const showAssignmentComplianceDataTable = useRecoilValue(
    assignmentComplianceDataTableState,
  );

  const showLocations = useRecoilValue(viewerShowLocations);
  const selectedTab = useRecoilValue(simulationComplianceTabKey);

  const content = [
    {
      id: 'tab-compliance-policy',
      title: t`Storage Policy`,
      getTable: () => <AssignmentComplianceTable />,
      getLocationLayer: () => <AssignmentComplianceLocationLayer />,
      // getToolbars: () => <AssignmentOccupancyToolbar />,
    },
    {
      id: 'tab-compliance-volume',
      title: t`Stock occupancy`,
      getTable: () => <AssignmentOccupancyTable />,
      getLocationLayer: () => <AssignmentOccupancyLocationLayer />,
      getToolbars: () => <AssignmentOccupancyToolbar />,
    },
    {
      id: 'tab-compliance-weight',
      title: t(`Weight compliance: {{featureType}}`, {
        featureType: showLocations ? t`Locations` : t`Bays`,
      }),
      getTable: () =>
        showLocations ? (
          <WeightComplianceLocationsTable />
        ) : (
          <WeightComplianceBaysTable />
        ),
      getLocationLayer: () => <WeightComplianceLocationLayer />,
      getToolbars: () => <WeightComplianceToolbar />,
    },
    {
      id: 'tab-compliance-size',
      title: t`Size compliance: Locations`,
      getTable: () => <SizeComplianceLocationsTable />,
      getLocationLayer: () => <SizeComplianceLocationLayer />,
      getToolbars: () => <SizeComplianceToolbar />,
    },
  ];

  const contentItem = _.find(content, c => c.id === selectedTab);

  const viewAs = [
    {
      key: 'table',
      label: t`Table`,
    },
    {
      key: 'layout',
      label: t`Layout`,
    },
  ];

  return (
    <Container
      componentName="SimulationComplianceViewContainer"
      col
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
      )}
    >
      <Container row hasNoScroll className={classNames('relative !h-auto')}>
        <HeaderAppMenu
          title={contentItem?.title}
          className={classNames('flex-1')}
        >
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <ButtonSwitchMulti
            autoSize
            className="mx-3"
            classNameLabel="text-xs py-1 px-2"
            buttonType="header"
            options={viewAs}
            selectedIndex={showTable ? 0 : 1}
            onClick={index => setShowTable(index === 0)}
          />
        </HeaderAppMenu>
      </Container>
      {showTable && showAssignmentComplianceDataTable ? (
        contentItem.getTable()
      ) : (
        <Container
          componentName={`SimulationComplianceLayoutViewContainer`}
          overflow
          flex1
          className={classNames(
            'relative',
            'border-app-background/70 border-8',
          )}
        >
          <Container
            componentName={`SimulationComplianceLayoutView`}
            col
            overflow-hidden
            className={classNames('relative', 'flex-1')}
          >
            <SimulationComplianceLayoutView
              getLocationLayer={contentItem.getLocationLayer}
              getAbsoluteContent={contentItem.getToolbars}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default SimulationComplianceView;

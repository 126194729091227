import { AP_FALLBACK_RULE_ID } from '../simulation/store/assignmentPolicy.default';

export const COLLAPSIBLE_TYPES_OPENED = [
  AP_FALLBACK_RULE_ID,
  'panel-navigator-helper',
  'panel-items-orders',
  'panel-order-stats-items',
  'panel-area-floor-assignment',
  'panel-area-properties',
  'panel-area-aisle-properties',
  'panel-area-bay-properties',
  'panel-routes-stats',
  'panel-routes-details',
  'panel-object-property-location-designer',

  'panel-aisle-properties',
  'panel-assignment-stats-item',

  'dashboard-simulation',
  'dashboard-slotting',
  'dashboard-data-layout-import',
  'dashboard-data-layout',
  'dashboard-data-assignment',
  'dashboard-data-order-set',
  'dashboard-data-item-set',
  'dashboard-data-dataset-objects',

  'panel-compliance-weight-bay',
  'panel-compliance-weight-items',
  'panel-compliance-size-items',

  'subscription-panel-demo',
  'subscription-panel-trial',
  'subscription-panel',
  'subscription-panel-features',
] as const;

export const COLLAPSIBLE_TYPES_CLOSED = [
  'panel-help-getting-started',
  // if not specified, panels will be collapsed by default
  'panel-help-documentation',
  'panel-help-support',
  'panel-help-chat',
  'panel-system-language',
  'panel-system-username',
  'panel-system-gdpr',
  'panel-system-legal',
  'panel-system-about',
  'panel-dataset-layout',
  'panel-dataset-assignments',
  'panel-dataset-orders',

  'panel-policy-routing',
  'panel-policy-cost',
  'panel-object-properties',
  'panel-object-properties-location',
  'panel-object-actions',
  'panel-object-align',
  'panel-object-distribute',
  'panel-object-locator',
  'panel-object-policy-access',
  'panel-object-policy-storage',
  'panel-object-property-size',
  'panel-object-property-bay',
  'panel-object-property-aisle',
  'panel-object-property-location',
  'panel-order-stats-details',
  'panel-aisle',
  'panel-bay-order',
  'panel-bay-properties',
  'panel-bay-spacers',
  'panel-bay-locations',
  'panel-layout-properties',
  'panel-stats-layout',
  'panel-stats-orders',
  'panel-stats-assignment',
  'panel-toolbar-reordering',
  'subscription-panel-bundles',
  'header-event-',
] as const;

export const COLLAPSIBLE_OPEN_PREFIX = [
  'location-stat-',
  'bay-type-settings-',
  'picklist-events-',
  'helper-',
  'resource-policy-agent-id-',
  'resource-policy-editor-section-',
  'resource-policy-editor-setup-section-',
  'resource-policy-agent-summary-section-',
  'ap-filter-tag-rule-',
  'picklist-end-',
  'picklist-start-',
  'panel-toolbar-',
  'agent-simulation-setting-',
  'picking-policy-what-',
  'picking-policy-rules-',
  'policy-waypoint-rule-',
  'pipeline-connector-',
  'stacking-policy-rule-',
  'routing-editor-plane-',
  'agent-simulation-schedule-',
  'agent-simulation-shift-',
  'dataset-metric-group-',
  'pipeline-setting-group-',
];

const COLLAPSIBLE_TYPE = [
  ...COLLAPSIBLE_TYPES_OPENED,
  ...COLLAPSIBLE_TYPES_CLOSED,
] as const;

export type CollapsibleState = {
  isCollapsed: boolean;
};

type CollapsibleTypeOpened = (typeof COLLAPSIBLE_TYPES_OPENED)[number];
type CollapsibleTypeClosed = (typeof COLLAPSIBLE_TYPES_CLOSED)[number];

export type CollapsibleId =
  | CollapsibleTypeOpened
  | CollapsibleTypeClosed
  | string; // comment, to check for collapsible errors
export type CollapsibleStateMap = Record<CollapsibleId, CollapsibleState>;
export type CollapsibleFilterStateMap = Record<string, CollapsibleState>;

export function getDefaultCollapsibleState(): CollapsibleStateMap {
  const opened = COLLAPSIBLE_TYPES_OPENED.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        isCollapsed: false,
      },
    }),
    {},
  );
  const closed = COLLAPSIBLE_TYPES_CLOSED.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        isCollapsed: true,
      },
    }),
    {},
  );

  return { ...opened, ...closed } as CollapsibleStateMap;
}

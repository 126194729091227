import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import { InferResult, sql } from 'kysely';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedItemsWithRank = InferResult<
  ReturnType<typeof getFeedItemsWithRanksQuery>
>;
export type FeedItemWithRank = FeedItemsWithRank[number];

export const getFeedItemsWithRanksQuery = (
  params: ActivityFeedQueryBuilderParams,
) => {
  return datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .with(
      cte => cte('tTotal'),
      db =>
        db
          .selectFrom('af')
          .select(({ fn }) => [
            fn
              .count<number>(
                sql<any>`if(${sql.ref('eventProcessType')} = ${ActivityEventProcessType.PICKING},1, null )`,
              )
              .as('totalCount'),
          ]),
    )
    .with(
      cte => cte('t1'),
      db =>
        db
          .selectFrom('af')
          .select(({ fn }) => [
            'skuKey',
            'consignee',
            'sku',
            fn
              .count<number>(
                sql<any>`if(${sql.ref('eventProcessType')} = ${ActivityEventProcessType.PICKING},1, null )`,
              )
              .as('pickCount'),
          ])
          .where(sql.ref('sku'), 'is not', null)
          .groupBy(['skuKey', 'consignee', 'sku']),
    )
    .selectFrom('t1')
    .innerJoin('tTotal', 'tTotal.totalCount', 'tTotal.totalCount')
    .select([
      't1.skuKey',
      't1.consignee',
      't1.sku',
      't1.pickCount',
      sql<number>`cast(SUM(${sql.ref('t1.pickCount')}) OVER (
      ORDER BY ${sql.ref('t1.pickCount')} desc
    )  as double) /${sql.ref('tTotal.totalCount')}`.as('cmlPercentRank'),
      sql<number>`percent_rank()  OVER (
      ORDER BY ${sql.ref('t1.pickCount')} desc)`.as('percentRank'),
    ])
    .where('t1.pickCount', '>', 0)
    .union(db =>
      db
        .selectFrom('t1')
        .select([
          't1.skuKey',
          't1.consignee',
          't1.sku',
          't1.pickCount',
          sql<number>`null`.as('cmlPercentRank'),
          sql<number>`null`.as('percentRank'),
        ])
        .where('t1.pickCount', '=', 0),
    );
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return (
    fName === 'previousStartTime' ||
    fName === 'eventStartTime' ||
    fName === 'eventEndTime'
  );
};

export function postProcessFeedItemsWithRank(
  events: Record<string, any>[],
): FeedItemsWithRank {
  return postProcessDefault<FeedItemWithRank>(events, isDateField);
}

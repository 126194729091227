import { signOut } from 'aws-amplify/auth';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MenuGlobal } from '../../components/MenuGlobal';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import PanelContainer from '../../containers/PanelContainer';
import StatManagementComponent from '../../feed/components/FeedFeatureManagement';
import FeedSettingsDataPanel from '../../feed/panels/FeedSettingsDataPanel';
import { appIsEmbeddedState } from '../../store/global.state';
import FeedGlobalFilterPanel from '../components/FeedGlobalFilterPanel';
import ProcessTypeFilters from '../components/ProcessTypeFilters';
import ResourceFilters from '../components/ResourceFilters';
import FeedExtraSettingsPanel from './FeedExtraSettingsPanel';
import FeedResourcePolicyPanel from './FeedResourcePolicyPanel';

const FeedSetupPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const logout = signOut;
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);

  console.log('FeedSetupPanel: isAppEmbedded', isAppEmbedded);

  return (
    <div className={classNames('h-full')}>
      {isAppEmbedded && (
        <MenuGlobal
          className="bg-menu p-1"
          config={{
            setting: { show: true, showTitle: true },
            notifications: { show: false, showTitle: false },
            help: { show: true, showTitle: true },
            subscription: { show: false, showTitle: false },
          }}
        />
      )}

      <ScreenTitle
        title={t`Setup`}
        subtitle={t`Feed`}
        helpNavTo={'actuality/actuality-setup'}
        icon={Icon.Settings3}
        // isSticky
        // className="top-8"
      />
      <PanelContainer
        id="panel-feed-settings-data"
        title={t`Data Settings`}
        collapsible
        // hasPadding
      >
        <FeedSettingsDataPanel />
      </PanelContainer>

      <PanelContainer
        id="panel-feed-settings-kpi-stats"
        title={t`Feature Visibility`}
        collapsible
        // hasPadding
      >
        <StatManagementComponent />
        {/* <FeedFeaturesPermissionManagementComponent /> */}
      </PanelContainer>

      <PanelContainer
        id="panel-feed-resource-policy"
        title={t`Resource Policy`}
        collapsible
        // hasPadding
      >
        <FeedResourcePolicyPanel />
      </PanelContainer>

      <ResourceFilters />

      <ProcessTypeFilters />

      <FeedGlobalFilterPanel />
      <FeedExtraSettingsPanel />

      {/* <PanelContainer
        id="panel-logout"
        title={t`Logout`}
        collapsible
        // hasPadding
      >
        <Button
          className={cn(
            'backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter',
          )}
          buttonSize={`lg`}
          label={t`Logout`}
          full
          buttonType={`primary`}
          hasIconAfter
          onPress={logout}
        />
      </PanelContainer> */}
    </div>
  );
};

export default FeedSetupPanel;

//Policies versions
export const PRIVACY_POLICY_VERSION = '1.0';
export const TERMS_POLICY_VERSION = '1.0';

// Local Storage Version
export const IMPORTER_SCHEMA_VERSION = '1.2.0';

// Workforce forecast
export const WORKFORCE_FORECAST_VERSION = '2022.12.001';

//STRING_CONSTANTS
export const WAREHOUSE_NEW_ID = 'new';

//AUTH
export const REFRESH_TOKEN_REQUEST_RETRY_COUNT = 3;

//Policy filters
export const POLICY_FILTER_VALUES_PAGE_SIZE = 20;
export const DATASET_VIEW_PAGE_SIZE = 100;

export const ANALYZE_ORDERS_PAGE_SIZE = 20;

//TIMEOUTS
export const IMPORT_JOB_REFRESH_INTERVAL = 3000;
export const IMPORT_POST_PROCESS_REFRESH_INTERVAL = 3000;
export const ANALYZE_PROGRESS_REFRESH_INTERVAL = 1000;
export const COMPLIANCE_PROGRESS_REFRESH_INTERVAL = 3000;
export const ANALYZE_WAYPOINT_HIGHLIGHT_TIMEOUT = 1500;
export const EXPORT_PROGRESS_REFRESH_INTERVAL = 5000;
export const DASHBOARD_REFRESH_INTERVAL = 60000;
export const ISSUES_PROGRESS_REFRESH_INTERVAL = 3000;
export const CHECK_POLICY_JOB_REFRESH_INTERVAL = 3000;
export const GEN_REPORT_JOB_REFRESH_INTERVAL = 3000;
export const ALLOCATION_JOB_REFRESH_INTERVAL = 3000;

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import ButtonLocateLocation from '../../components/ButtonLocateLocation';
import { ColumnConfig } from '../../components/DatasetTable';
import { getActualityMetricDescriptorsMap } from '../../metrics/actuality/actualityMetric.default';
import ItemTag from '../../simulation/tags/ItemTag';
import { actualityMetricSelected } from '../store/actuality.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';
// Import the function to get bucket color (you'll need to create or import this)

function useActualityHeatmapDataTableConfig(): ColumnConfig<ActualityHeatmapDataRow>[] {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();

  const heatmapMetricId = useRecoilValue(actualityMetricSelected);
  const metricDescriptors = getActualityMetricDescriptorsMap(t, formatter);
  const metric = metricDescriptors[heatmapMetricId];

  const columnsConfig: ColumnConfig<ActualityHeatmapDataRow>[] = [
    {
      field: 'locationId',
      title: t`Location`,
      isHeader: true,
      hasFilter: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'skuKey',
      title: t`Consignee`,
      hasFilter: true,
      render: (skuKey: string) => skuKey?.split('-')[0] ?? '',
    },
    {
      field: 'skuKey',
      title: t`SKU`,
      hasFilter: true,
      render: (skuKey: string, row) => {
        const [consignee, sku] = skuKey?.split('-') ?? [];
        return (
          <ItemTag
            title={sku}
            filters={{ consignee, sku }}
            // color={getQualitativeColor(sku, 'dimension')[0]}
          />
        );
      },
    },
    {
      field: 'dimensionValue',
      title: metric.title,
      render: v => (_.isNumber(v) && metric.format ? metric.format(v) : v),

      //   render: v => {
      //     const formatteddimensionValue =
      //       _.isNumber(v) && metric.format ? metric.format(v) : v;

      //     return (
      //       <LegendItem
      //         // classNameText={classNames('max-w-ch-20 truncate text-ellipsis')}
      //         color={getQualitativeColor(metric.title, 'dimension')[0]}
      //         text={formatteddimensionValue.toString()}
      //         labelRaw={metric.title}
      //         // isHidden={isHidden}
      //         // onClick={evt => {
      //         //   debouncedClick(bucket.id, !isHidden, evt);
      //         // }}
      //       />
      //     );
      //   },
    },
  ];

  return columnsConfig;
}

export default useActualityHeatmapDataTableConfig;

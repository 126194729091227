import {
  ACTIVITY_FEED_MAPPING_SCHEMA,
  ActivityFeedEvent,
  MappingSchemaField,
} from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';

export function getActivityFeedSchema(
  t: TFunction<'importer'>,
): MappingSettings<ActivityFeedEvent> {
  const baseFields = keyBy(
    ACTIVITY_FEED_MAPPING_SCHEMA.fields,
    f => f.name,
  ) as Record<keyof ActivityFeedEvent, MappingSchemaField<ActivityFeedEvent>>;

  return {
    ...ACTIVITY_FEED_MAPPING_SCHEMA,
    fields: [
      {
        ...baseFields.eventId,
        title: t(`Event ID`, { ns: 'importer' }),
        description: t(
          `A unique event ID logged in the warehouse, ensuring that each transaction can be uniquely traced. (Alias Examples: Event ID, Transaction ID, Log Line ID)`,
          { ns: 'importer' },
        ),
        example: '123456789',
        aliases: [
          'id',
          'eventid',
          'activityid',
          'actionid',
          'auditid',
          'logid',
          'picklistid',
          'listid',
          'listgroupid',
          'WarehouseTask',
          'taskId',
          'missionId',
          'internal_id',
          'internalid',
        ],
      },
      {
        ...baseFields.eventProcessType,
        title: t('Process type', { ns: 'importer' }),
        description: t(
          'Process type category, such as Picking, Packing, Replenishment, Putaway etc.',
          {
            ns: 'importer',
          },
        ),
        example: 'PICKING',
        aliases: [
          'eventProcessType',
          'WhseProcessType',
          'Process',
          'Type',
          'processType',
          'work_type',
        ],
        valueResolver: baseFields.eventProcessType.defaultValueResolver,
      },
      {
        ...baseFields.eventType,
        title: t('Event type', { ns: 'importer' }),
        description: t(
          'Provides additional details about the event, helping to clarify the specific action taken or the status update reported. (Pick, Put, Move, etc.)',
          {
            ns: 'importer',
          },
        ),
        example: 'Pick',
        aliases: ['eventType', 'actionType'],
        valueResolver: baseFields.eventType.defaultValueResolver,
      },
      {
        ...baseFields.consignee,
        title: t(`Client (Consignee)`, { ns: 'importer' }),
        description: t(
          `Client (consignee) — Name of your company (can be generated) or your client (For 3PLs)`,
          { ns: 'importer' },
        ),
        example: 'Client A',
        aliases: [
          'consignee',
          'consigneeid',
          'idconsignee',
          'client',
          'clientid',
          'clt',
          'cltcde',
          'owner',
          'ownerId',
          'clinet',
          'clientcode',
          'idclient',
          'idvendor',
          'vendor',
          'depositor',
          'car_name',
          'carname',
          'name',
          'Prod.OwnerId',
          'ProdOwnerId',
          'ProdOwner',
          'orderclientcode',
          'company',
        ],
      },
      {
        ...baseFields.sku,
        title: t(`Item`, { ns: 'importer' }),
        description: t(
          `The stock keeping unit (SKU) code for the item involved in the event, essential for inventory management and tracking. (Item code, SKU, Product ID, Part Number, Article etc.)`,
          { ns: 'importer' },
        ),
        example: 'SKU1234567890',
        aliases: [
          'sku',
          'skuid',
          'sku/item',
          'item',
          'itemid',
          'itemnumber',
          'itemserialnumber',
          'itemsn',
          'itemcode',
          'itemsku',
          'itmnum',
          'itm',
          'itmcde',
          'number',
          'code',
          'inventory',
          'stock',
          'lot',
          'product',
          'productid',
          'productnumber',
          'productcode',
          'partnumber',
          'prodversion',
          'prodversionid',
          'prodid',
          'prodcode',
          'prod',
          'material',
          'tuotenumero',
          'article',
          'articleid',
          'article_id',
          'warehouse_sku_id',
          'warehouseskuid',
          'warehouse_sku',
          'warehousesku',
        ],
      },
      {
        ...baseFields.uom,
        title: t(`UOM`, { ns: 'importer' }),
        description: t(
          `Unit of measure (UOM), such as individual pieces, cases, layers or pallets.`,
          { ns: 'importer' },
        ),
        example: 'PALLET',
        aliases: [
          'uom',
          'um',
          'unitofmeasure',
          'unitsofmeasure',
          'unit',
          'fixedunitsymbolassignment',
          'unitsymbol',
          'untuom',
          'layuom',
          'articletype',
          'article_type',
          'quantity_uom',
          'quantity_um',
          'qty_um',
          'quantity_unit',
        ],
      },

      {
        ...baseFields.quantity,
        title: t('UOM quantity', { ns: 'importer' }),
        description: t(
          'The quantity of the item (UOM) handled, expressed in the unit of measure specified.',
          {
            ns: 'importer',
          },
        ),
        example: '1',
        aliases: ['quantity', 'qty', 'uomquantity', 'ActualQuantity'],
      },

      {
        ...baseFields.scanCode,
        title: t('Scan code', { ns: 'importer' }),
        description: t(
          'The barcode (EAN/UPC) or QR codes associated with the item.',
          {
            ns: 'importer',
          },
        ),
        example: '123456789',
        aliases: ['scanCode', 'scan_code', 'qrcode', 'barcode', 'ean', 'upc'],
      },

      {
        ...baseFields.locationIdSource,
        title: t('Location ID Source', { ns: 'importer' }),
        description: t(
          'Location ID (Source/From), typically in the format AA010101.',
          {
            ns: 'importer',
          },
        ),
        example: 'AA010101',
        aliases: ['locationIdSource', 'FromLocation', , 'From'],
      },

      {
        ...baseFields.warehouseAreaSource,
        title: t('Area Source', { ns: 'importer' }),
        description: t(
          'Warehouse Area (Source), providing additional location data for the event.',
          { ns: 'importer' },
        ),
        example: 'Area X',
        aliases: ['warehouseAreaSource'],
      },
      {
        ...baseFields.locationId,
        title: t('Location ID', { ns: 'importer' }),
        description: t('Location ID/Name, typically in the format AA010101', {
          ns: 'importer',
        }),
        example: 'AA010101',
        aliases: [
          'locationId',
          'locationname',
          'location',
          'containerid',
          'container',
          'binid',
          'bin',
          'pickface',
          'pickfaceid',
          'pickfacelocationid',
          'rakusr',
          'blbinl',
          'WHSE_SLT_LOC_ID',
          'SourceStorageBin',
          'StorageBin',
          'Storage',
          'ToLocationName',
          'ToLocationId',
          'ToLocation',
        ],
      },
      {
        ...baseFields.warehouseArea,
        title: t('Area', { ns: 'importer' }),
        description: t(
          'Warehouse Area (Dest.), providing additional location data for the event.',
          { ns: 'importer' },
        ),
        example: 'Area X',
        aliases: [
          'warehousearea',
          'area',
          'zone',
          'warehousezone',
          'zonewarehouse',
          'areawarehouse',
          'sectorwarehouse',
          'sector',
          'segment',
          'block',
        ],
      },

      {
        ...baseFields.agentType,
        title: t('Agent type', { ns: 'importer' }),
        description: t(
          'The type of MHE, Device or Team involved in the event, categorizing the resources used for different operations.',
          {
            ns: 'importer',
          },
        ),
        example: 'Forklift',
        aliases: ['agentType', 'deviceType', 'device_model', 'agent_model'],
      },
      {
        ...baseFields.agentId,
        title: t('Agent ID', { ns: 'importer' }),
        description: t(
          'The ID for the specific equipment/device or user involved in the event, linking actions to individual agents for accountability and performance tracking.',
          {
            ns: 'importer',
          },
        ),
        example: 'Forklift #01',
        aliases: ['agentId', 'Confirmedby', 'Device'],
      },
      {
        ...baseFields.agentUser,
        title: t('Agent User', { ns: 'importer' }),
        description: t(
          'The username of the individual or the (SN) identifier of the automated agent (like a robot or device) involved in the event, providing a direct or obfuscated link to personnel or equipment productivity.',
          {
            ns: 'importer',
          },
        ),
        example: 'Employee Username #01',
        aliases: [
          'agentUser',
          'user',
          'user_id',
          'userid',
          'user_name',
          'username',
          'Confirmedby',
          'Employee',
        ],
      },
      {
        ...baseFields.agentEnergy,
        title: t('Agent Energy', { ns: 'importer' }),
        description: t(
          'The energy level or battery percentage of the device or equipment.',
          {
            ns: 'importer',
          },
        ),
        example: '99',
        aliases: [
          'agentEnergy',
          'energy',
          'battery',
          'device_battery',
          'agent_battery',
        ],
      },
      {
        ...baseFields.agentSignalStrength,
        title: t('Signal strength', { ns: 'importer' }),
        description: t(
          'The strength of the WiFi/Modem signal for the device involved in the event, which can impact the reliability of event logging and real-time data communication.',
          {
            ns: 'importer',
          },
        ),
        example: '50',
        aliases: [
          'agentSignalStrength',
          'dbm',
          'signal',
          'wifi',
          'SignalStrength',
        ],
      },

      {
        ...baseFields.eventStartTime,
        title: t(`Event start timestamp`, { ns: 'importer' }),
        description: t(
          `The exact time when the event started, optional value for more detailed tracking if exists.`,
          {
            ns: 'importer',
          },
        ),
        example: '2020-01-01 15:58:01',
        contextHelpId: 'importer/date-time',
        aliases: [
          'eventStartTime',
          'eventTimeStart',
          'event_Time_Start',
          'start',
          'timeStart',
          'startTime',
          'time_received',
          'time_start',
        ],
      },
      {
        ...baseFields.eventEndTime,
        title: t(`Event end timestamp`, { ns: 'importer' }),
        description: t(
          `The exact time when the event ended, primary value for time tracking and efficiency analysis`,
          {
            ns: 'importer',
          },
        ),
        example: '2020-01-01 15:58:01',
        contextHelpId: 'importer/date-time',
        aliases: [
          'eventEndTime',
          'eventTimeEnd',
          'event_Time_End',
          'end',
          'endTime',
          'TimeEnd',
          'time_end',
          'time_scanned',
          'time_created',
        ],
      },
      {
        ...baseFields.jobId,
        title: t(`Referenced Job ID`, { ns: 'importer' }),
        description: t(
          `Groups this event with related tasks or operations under a common job identifier, such as a Picklist ID, List ID, Work Assignment, Mission or Task Id, or any other ID that groups the sequence of events together.`,
          {
            ns: 'importer',
          },
        ),
        example: 'P123456798',
        aliases: [
          'jobId',
          'picklistId',
          'listId',
          'listGroupId',
          'PickOrderID',
          'LineId',
          'Line#',
          'work_unit',
        ],
      },
      {
        ...baseFields.jobGroupId,
        title: t(`Job group ID`, { ns: 'importer' }),
        description: t(
          `Links the event to a broader group of related tasks, such as client PO Orders, waves or batches, facilitating large-scale operation management`,
          {
            ns: 'importer',
          },
        ),
        example: 'J-123456798',
        aliases: [
          'jobGroupId',
          'orderId',
          'batchId',
          'documentId',
          'waveId',
          'wave',
          'WarehouseOrder',
          'erpOrder',
          'order',
          'Queue',
          'reference_id',
        ],
      },
      {
        ...baseFields.jobLine,
        title: t(`Job line number`, { ns: 'importer' }),
        description: t(
          `An optional identifier that can further detail the sequence of tasks within a job, aiding in detailed tracking and sequence management.`,
          {
            ns: 'importer',
          },
        ),
        example: '1',
        aliases: ['jobLine', 'sequence', 'lineNumber', 'line_number'],
      },
      // {
      //   ...baseFields.partition,
      //   title: t(`Partition`, { ns: 'importer' }),
      //   description: t(`WareBee Data Storage Partition (Internal)`, {
      //     ns: 'importer',
      //   }),
      //   example: '2023-12-31',
      //   aliases: ['partition', 'partition_date'],
      // },
    ],
  };
}

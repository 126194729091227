import {
  LayoutLocationsSummaryGroupBy,
  LayoutLocationsSummaryUomSpec,
  MeasurementSystem,
} from '@warebee/frontend/data-access-api-graphql';
import {
  LocationCapacityId,
  LocationsStatsDataRow,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ColumnConfig } from '../../components/DatasetTable';
import i18n from '../../i18n';
import palletSpecData from '../../simulation/data/palletSpec.json';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';

export type LocationsCapacitySpec = LayoutLocationsSummaryUomSpec & {
  id: LocationCapacityId;
  title: string;
};

export type LocationsStatsDimensionId =
  | keyof typeof LayoutLocationsSummaryGroupBy
  | 'aisle-side';

export type LocationsStatsDimension = ColumnConfig<LocationsStatsDataRow> & {
  id: LocationsStatsDimensionId;
  groupBy: LayoutLocationsSummaryGroupBy[];
};

export function useLocationCapacitySpec() {
  const { t } = useTranslation('simulation');
  const ms = useRecoilValue(warehouseMeasurementSystem);

  return getLocationCapacitySpec(t, ms);
}

const getLocationCapacitySpec = (
  t: TFunction<'simulation'>,
  ms: MeasurementSystem,
): LocationsCapacitySpec[] => {
  const isMetric = ms === MeasurementSystem.METRIC;
  const specManualResults = [
    {
      id: 'max-uom' as LocationCapacityId,
      title: t('Max UOM', { ns: 'simulation' }),
      width: isMetric ? 40 : 40,
      length: isMetric ? 44 : 44,
      height: isMetric ? 50 : 50,
    },
    {
      id: 'min-uom' as LocationCapacityId,
      title: t('Min UOM', { ns: 'simulation' }),
      width: isMetric ? 40 : 20, // 40 cm (EU) or 20 inches (US)
      length: isMetric ? 60 : 40, // 60 cm (EU) or 40 inches (US)
      height: isMetric ? 58 : 24, // 60 cm (EU) or 24 inches (US)
    },
  ];

  // Results from original getLocationCapacitySpec
  const specPalletResults = palletSpecData.palletSpecs.map(spec => {
    const sizeDefaults =
      ms === MeasurementSystem.IMPERIAL
        ? {
            width: Math.round(spec.sizeDefaults.metric.width / 10),
            length: Math.round(spec.sizeDefaults.metric.length / 10),
            height: Math.round(spec.sizeDefaults.metric.height / 10),
          }
        : {
            width: spec.sizeDefaults.imperial.width,
            length: spec.sizeDefaults.imperial.length,
            height: spec.sizeDefaults.imperial.height,
          };

    return {
      id: spec.palletTypeId as LocationCapacityId,
      title: t(spec.title, { ns: 'simulation' }),
      ...sizeDefaults,
    };
  });

  // Combine both results
  const combinedResults = [
    ...specManualResults,
    // ...specPalletResults
  ];

  console.log('Combined LocationsCapacitySpec results:', combinedResults);
  console.log('specManualResults:', specManualResults);
  console.log('specPalletResults:', specPalletResults);

  return combinedResults;
};

// export const getLocationCapacitySpec2 = (
//   t: TFunction<'simulation'> = i18n.t,
// ): LocationsCapacitySpec[] => {
//   const measurementSystem = 'METRIC';
//   const result = palletSpecData.palletSpecs.map(spec => {
//     const sizeDefaults =
//       measurementSystem === MeasurementSystem.METRIC
//         ? {
//             width: Math.round(spec.sizeDefaults.metric.width / 10),
//             length: Math.round(spec.sizeDefaults.metric.length / 10),
//             height: Math.round(spec.sizeDefaults.metric.height / 10),
//           }
//         : {
//             width: spec.sizeDefaults.imperial.width,
//             length: spec.sizeDefaults.imperial.length,
//             height: spec.sizeDefaults.imperial.height,
//           };

//     return {
//       id: spec.palletTypeId as LocationCapacityId,
//       title: t(spec.title, { ns: 'simulation' }),
//       ...sizeDefaults,
//     };
//   });

//   console.log('getLocationCapacitySpec result:', result);
//   console.log('palletSpecData:', palletSpecData);
//   return result;
// };

export const getLocationsStatsDimensions = (
  t: TFunction<'simulation'> = i18n.t,
): Record<LocationsStatsDimensionId, LocationsStatsDimension> => {
  const dims: LocationsStatsDimension[] = [
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE],
      title: t('Location Type', { ns: 'simulation' }),
      field: 'locationRackingType',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.ACCESS_AISLE,
      groupBy: [LayoutLocationsSummaryGroupBy.ACCESS_AISLE],
      title: t('Access', { ns: 'simulation' }),
      field: 'accessAisleTitle',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.AISLE,
      groupBy: [LayoutLocationsSummaryGroupBy.AISLE],
      title: t('Aisle', { ns: 'simulation' }),
      field: 'aisleTitle',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.LEVEL,
      groupBy: [LayoutLocationsSummaryGroupBy.LEVEL],
      title: t('Level', { ns: 'simulation' }),
      field: 'locationLevel',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.SIDE,
      groupBy: [LayoutLocationsSummaryGroupBy.SIDE],
      title: t('Side', { ns: 'simulation' }),
      field: 'locationSide',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA,
      groupBy: [LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA],
      title: t('Area', { ns: 'simulation' }),
      field: 'warehouseArea',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_STATUS,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_STATUS],
      title: t('Status', { ns: 'simulation' }),
      field: 'locationStatus',
      hasFilter: true,
      filterOptions: {
        true: t('Enabled', { ns: 'simulation' }),
        false: t('Disabled', { ns: 'simulation' }),
      },
      render: v =>
        v ? (
          <span
            className={classNames(
              'text-minimal uppercase',
              'rounded',
              'px-3 py-1',
              'bg-alerts-okay text-alerts-warningText',
            )}
          >
            {t('Enabled', { ns: 'simulation' })}
          </span>
        ) : (
          <span
            className={classNames(
              'text-minimal uppercase',
              'rounded',
              'px-3 py-1',
              'bg-menu-active/30 text-menu-active-text',
            )}
          >
            {t('Disabled', { ns: 'simulation' })}
          </span>
        ),
    },
    {
      id: LayoutLocationsSummaryGroupBy.ASSIGNED,
      groupBy: [LayoutLocationsSummaryGroupBy.ASSIGNED],
      title: t('Occupancy', { ns: 'simulation' }),
      field: 'assigned',
      hasFilter: true,
      filterOptions: {
        true: t('Occupied', { ns: 'simulation' }),
        false: t('Empty', { ns: 'simulation' }),
      },
      render: v =>
        v ? (
          <span
            className={classNames(
              'text-minimal uppercase',
              'rounded',
              'px-3 py-1',
              'bg-location-selected text-menu-active-text',
            )}
          >
            {t('Occupied', { ns: 'simulation' })}
          </span>
        ) : (
          <span
            className={classNames(
              'text-minimal uppercase',
              'rounded',
              'px-3 py-1',
              'bg-app-panel-dark/70 text-location-empty',
            )}
          >
            {t('Empty', { ns: 'simulation' })}
          </span>
        ),
    },
    {
      id: 'aisle-side',
      groupBy: [
        LayoutLocationsSummaryGroupBy.AISLE,
        LayoutLocationsSummaryGroupBy.SIDE,
      ],
      title: t('Aisle and Side', { ns: 'simulation' }),
      field: 'aisleAndSide',
    },
    {
      id: LayoutLocationsSummaryGroupBy.CONSIGNEE,
      groupBy: [LayoutLocationsSummaryGroupBy.CONSIGNEE],
      title: t('Consignee', { ns: 'simulation' }),
      field: 'consignee',
    },
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_HEIGHT,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_HEIGHT],
      title: t('Location Height', { ns: 'simulation' }),
      field: 'locationHeight',
    },
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_WIDTH,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_WIDTH],
      title: t('Location Width', { ns: 'simulation' }),
      field: 'locationWidth',
    },
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_LENGTH,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_LENGTH],
      title: t('Location Depth', { ns: 'simulation' }),
      field: 'locationLength',
    },
  ];

  return _.keyBy(dims, 'id') as Record<
    LocationsStatsDimensionId,
    LocationsStatsDimension
  >;
};

export const locationStatsBaseDrillDownDimensions: LocationsStatsDimensionId[] =
  [
    LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
    LayoutLocationsSummaryGroupBy.AISLE,
    'aisle-side',
    LayoutLocationsSummaryGroupBy.LEVEL,
    LayoutLocationsSummaryGroupBy.ACCESS_AISLE,
    LayoutLocationsSummaryGroupBy.SIDE,
    LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA,
  ];

export const locationStatsDrillDownDimensions: LocationsStatsDimensionId[] = [
  ...locationStatsBaseDrillDownDimensions,
  LayoutLocationsSummaryGroupBy.CONSIGNEE,
];

export const locationStatsFixedDimensions = [
  LayoutLocationsSummaryGroupBy.LOCATION_STATUS,
  LayoutLocationsSummaryGroupBy.ASSIGNED,
];

export function getLocationsStatsTableConfigDefault(
  mainDimension: LocationsStatsDimensionId,
  t: TFunction<'simulation'>,
  ms: MeasurementSystem,
): ColumnConfig<LocationsStatsDataRow>[] {
  const dimensions = getLocationsStatsDimensions(t);
  return [
    ..._.map([mainDimension, ...locationStatsFixedDimensions], d => ({
      isHeader: true,
      ...dimensions[d],
    })),
    {
      field: 'locationCount',
      title: t(`Locations`, { ns: 'simulation' }),
      render: v => v ?? '—',
    },
    ..._.map(getLocationCapacitySpec(t, ms), l => ({
      field: l.id,
      title: l.title,
      render: l => l ?? '—',
    })),
  ];
}

import _ from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger, formatTimespan } from '../../common/formatHelper';
import { cn } from '../../common/utils';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import InboxZero from '../../components/InboxZero';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatListItem } from '../../components/stats/StatListItem';
import PanelContainer from '../../containers/PanelContainer';
import ItemTag from '../../simulation/tags/ItemTag';
import useActualityHeatmapItems from '../hooks/useActualityHeatmapItems';
import { feedLatestLocationEvents } from '../store/feed.state';
import FeedEventsList from './FeedEventsList';

export type FeedLocationItemsProps = {
  locationId: string;
};

const FeedLocationItems: React.FC<FeedLocationItemsProps> = props => {
  const { t } = useTranslation('feed');
  const { getLocationItems } = useActualityHeatmapItems();
  const events = useRecoilValue(feedLatestLocationEvents);

  // Add this new state
  const [selectedEventProcessType, setSelectedEventProcessType] =
    useState<string>('All');

  const stats = useMemo(() => {
    const eventsByProcessType = _.groupBy(events, 'eventProcessType');
    const statsPerType = _.mapValues(eventsByProcessType, typeEvents => {
      const totalQuantity = _.sumBy(typeEvents, 'quantity');
      const quantityByUOM = _.groupBy(typeEvents, 'uom');
      const congestionEvents = typeEvents.filter(
        event => event.congestion > 0,
      ).length;
      const timeSpent =
        typeEvents.length > 0
          ? new Date(_.last(typeEvents).eventEndTime).getTime() -
            new Date(_.first(typeEvents).previousStartTime).getTime()
          : 0;

      return {
        totalQuantity,
        quantityByUOM: Object.entries(quantityByUOM).map(([uom, events]) => ({
          uom,
          quantity: _.sumBy(events, 'quantity'),
        })),
        congestionEvents,
        timeSpent: timeSpent / 1000 / 60, // Convert to minutes
      };
    });

    return statsPerType;
  }, [events]);

  // Modify this memoized value
  const eventProcessTypes = useMemo(() => {
    const types = Object.keys(stats);
    return types.length > 1 ? ['All', ...types] : types;
  }, [stats]);

  // Add this new memoized value
  const showSelector = useMemo(
    () => eventProcessTypes.length > 1,
    [eventProcessTypes],
  );

  // Filter events based on selected type
  const filteredEvents = useMemo(() => {
    if (!showSelector || selectedEventProcessType === 'All') return events;
    return events.filter(
      event => event.eventProcessType === selectedEventProcessType,
    );
  }, [events, selectedEventProcessType, showSelector]);

  const items = getLocationItems(props.locationId);
  const sectionTitle = t`Items`;
  const hasItems = !_.isEmpty(items);

  if (!hasItems) {
    return <InboxZero message={t`No Data`} message_helper={`(No Events)`} />;
  }

  return (
    <PanelContainer
      id="panel-feed-stats-item"
      title={sectionTitle}
      collapsible
      hasPadding={false}
      className={cn('flex h-full flex-1 flex-col')}
      // isAccordion
      classNameChildren="flex flex-col h-full"
    >
      {/* <TitleSection inPanelView title={t`Item`} /> */}

      {/* Conditionally render the DropdownSelector */}
      {showSelector && (
        <div className="px-1 py-2">
          <DropdownSelector
            label={t`Process Type`}
            value={selectedEventProcessType}
            onChange={setSelectedEventProcessType}
            values={eventProcessTypes}
            panelMode
            className="text-xs"
          />
        </div>
      )}

      {_.map(items, item => (
        <Fragment key={item.skuKey}>
          <TitleSection
            data-component="ItemData"
            key={`item-location-data-group-${item.skuKey}`}
            id={`item-location-data-group-${item.skuKey}`}
            className={cn('py-1')}
            inPanelView
            collapsible={items.length > 1}
            title={
              <ItemTag
                title={item.itemRanked?.sku ?? item.skuKey}
                filters={{
                  consignee: item.itemRanked?.consignee,
                  sku: item.itemRanked?.sku,
                }}
              />
            }
            classNameInner={cn('space-y-px', 'flex flex-col', 'h-full')} // h-full
          >
            <TitleSection
              key={`item-stat-group-${item.skuKey}`}
              id={`item-stat-group-${item.skuKey}`}
              inPanelView
              // title={t`Item Stats`}
              title={
                <StatListItem
                  title={item.metricDescriptor.title}
                  // title={t`Stats:` + ' ' + item.metricDescriptor.title}
                  value={item.heatmapValue}
                  valueStyle={{
                    color: item.bucket?.color,
                    borderColor: item.bucket?.color,
                    borderWidth: item.bucket?.color ? 1 : 0,
                  }}
                />
              }
              collapsible
              classNameInner="space-y-px"
              className="flex-none border-b-0"
              customOpenIcon={
                <Icon.ChevronDualOpen className="hover:text-menu-active mx-1 h-4 w-4 fill-current" />
              }
              customClosedIcon={
                <Icon.CircleMenu className="hover:text-menu-active mx-1 h-4 w-4 fill-current" />
              }
            >
              {Object.entries(stats).map(([eventType, typeStats]) => {
                // Only render stats for selected event type or 'All'
                if (
                  selectedEventProcessType !== 'All' &&
                  selectedEventProcessType !== eventType
                )
                  return null;

                return (
                  <Stat
                    key={eventType}
                    title={eventType}
                    // value={formatInteger(typeStats.totalQuantity)}
                  >
                    {typeStats.quantityByUOM.map(({ uom, quantity }) => (
                      <StatListItem
                        key={uom}
                        title={t('Qty ({{uom}})', { uom })}
                        value={formatInteger(quantity)}
                      />
                    ))}
                    {typeStats.congestionEvents > 100 && (
                      <StatListItem
                        title={t`Congestion Events`}
                        value={formatInteger(typeStats.congestionEvents)}
                      />
                    )}
                    {typeStats.timeSpent > 0 && (
                      <StatListItem
                        title={t`Time Spent`}
                        value={formatTimespan(typeStats.timeSpent)}
                      />
                    )}
                  </Stat>
                );
              })}
              {/* <StatListItem
                title={item.metricDescriptor.title}
                value={item.heatmapValue}
                valueStyle={{
                  color: item.bucket?.color,
                  borderColor: item.bucket?.color,
                  borderWidth: item.bucket?.color ? 1 : 0,
                }}
              /> */}
            </TitleSection>
            {/* <div
              data-component="ItemDataEventsHistory"
              // className={cn('flex-1')}
              className="flex-1 flex-grow overflow-hidden"
            > */}
            <FeedEventsList
              events={filteredEvents}
              groupingOption="byHour"
              groupBy={item.itemRanked?.sku ?? item.skuKey}
              className="h-full flex-1 flex-grow"
            />
            {/* </div> */}
          </TitleSection>
        </Fragment>
      ))}
    </PanelContainer>
  );
};

export default FeedLocationItems;

import {
  QueryDatasetQuery,
  QueryDatasetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { postProcessDatasetQuery } from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type DatasetQueryExportJobParams = ExportJobParams<
  QueryDatasetQueryVariables,
  DatasetQueryConverterConfig
>;

export const datasetQueryExportJobParams: DatasetQueryExportJobParams = {
  converterId: 'DATASET_QUERY',
  query: null,
  config: null,
  variables: null,
  filename: 'feed-{{timestamp}}-{{whid}}.csv',
};

export type DatasetQueryDataRow = Record<string, string | number | Date>;
export type DatasetQueryDataColumn = keyof DatasetQueryDataRow;

export type DatasetQueryConverterConfig = {
  columnsConfig: ColumnConfigBase<Record<string, string | number | Date>>[];
  dateFields?: string[];
};

const isDateField = (
  f: string,
  config: DatasetQueryConverterConfig,
): boolean => {
  const fName = _.camelCase(f);
  return _.includes(config.dateFields, fName);
};

export const DATASET_QUERY_EXPORT_CONVERTER: ExportConverter<
  QueryDatasetQuery,
  DatasetQueryConverterConfig
> = (input, config) => {
  const data = postProcessDatasetQuery<DatasetQueryDataRow>(
    input.warehouse?.datasetObjectQuery?.result,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};

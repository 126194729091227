import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import PanelContainer from '../../containers/PanelContainer';
import { actualityResourcePolicy } from '../store/actuality.state';
import { feedDisabledAgentTypes } from '../store/feed.state';

const ResourceFilters: React.FC = () => {
  const { t } = useTranslation('feed');
  const [disabledAgentTypes, setDisabledAgentTypes] = useRecoilState(
    feedDisabledAgentTypes,
  );

  const policy = useRecoilValue(actualityResourcePolicy);

  const [disabledAgentTypesLocal, setDisabledAgentTypesLocal] =
    useState<string[]>();

  const disabledAgentsSet = new Set(disabledAgentTypesLocal);

  useEffect(() => {
    setDisabledAgentTypesLocal(disabledAgentTypes);
  }, [disabledAgentTypes]);

  function toggleAgentType(id: string, isSelect: boolean) {
    const others = _.filter(disabledAgentTypesLocal, at => at !== id);
    if (!isSelect) {
      others.push(id);
    }
    setDisabledAgentTypesLocal(others);
  }

  return (
    <PanelContainer
      id="panel-feed-global-filters-resource-type"
      title={t`Resource filters`}
      collapsible
      // hasPadding
    >
      <ScreenTitle
        title={t`Resources`}
        subtitle={t`Feed`}
        helpNavTo={'actuality/feed-resources'}
        icon={Icon.AgentWalking}
        isSticky
        className="top-8"
      />

      <TitleSection
        title={`Resources`}
        inPanelView
        className="z-400 top-12 xl:top-20"
        hasScreenTitle
        hasAction={
          <Button
            label={t`Apply filter`}
            className={classNames('rounded ltr:ml-4 rtl:mr-4')}
            buttonSize="xs"
            buttonType="primary"
            onPress={() => setDisabledAgentTypes(disabledAgentTypesLocal)}
          />
        }
      />

      {_.isEmpty(policy?.agents) && (
        <InboxZero message={t`No Resources found`} />
      )}

      <ul className={classNames('mb-6 space-y-6', 'p-4')}>
        {_.chain(policy?.agents)
          .sortBy('title')
          .map(agent => {
            const isDisabled =
              disabledAgentsSet.size !== 0 && disabledAgentsSet.has(agent.id);
            return (
              <div
                className={classNames('flex')}
                key={`resouce-global-filter-${agent.id}`}
              >
                <label className={classNames('flex-1 opacity-80')}>
                  {agent.title}
                </label>
                <ButtonSwitchMulti
                  autoSize
                  options={[{ label: t('off') }, { label: t('on') }]}
                  selectedIndex={isDisabled ? 0 : 1}
                  onClick={v => toggleAgentType(agent.id, v === 1)}
                />
              </div>
            );
          })
          .value()}
      </ul>
    </PanelContainer>
  );
};

export default ResourceFilters;
